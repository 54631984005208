<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <div class="mb-5">
                        <v-btn color="primary" :to="{name: 'emergency_call.status', params: {status: $route.params.status}}" class="back-btn" link large>
                            <v-icon>mdi-less-than</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-subheader class="headline">{{ heading }}</v-subheader>
                    </div>
                </div>

                <v-card outlined class="mb-4">
                    <v-card-text>
                        <div class="text-overline">
                            {{$t('who_caused_it')}}
                        </div>
                        <v-list dense >
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('caller_name')}}:</strong> {{ caller_name || $t('not_specified') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('caller_phone')}}:</strong> {{ formatPhoneNumber(caller_phoneRaw) }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
                <v-card outlined class="mb-4">
                    <v-card-text>
                        <div class="text-overline">
                            {{$t('patient_data')}}
                        </div>
                        <v-list dense >
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('fio')}}:</strong> {{ name || $t('not_specified') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('iin')}}:</strong> {{ iin }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('sex')}}:</strong> {{ sex }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('age')}}:</strong> {{ age }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('phone')}}:</strong> {{ formatPhoneNumber(phoneRaw) || $t('not_specified') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('city')}}:</strong> {{ city || $t('not_specified') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title><strong>{{ $t('address') }}:</strong>
                                    {{ street }}, {{$t('house')}} {{ house }},
                                    {{$t('entrance')}} {{ entrance || 'N/A' }}, {{$t('floor')}} {{ floor || 'N/A' }},
                                    {{$t('apartment')}} {{ apartment || 'N/A' }},
                                    {{$t('block')}} {{ block || 'N/A' }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('allergies')}}:</strong> {{ allergies || $t('not_specified') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-0">
                                <v-list-item-title>
                                    <strong>{{$t('chronic_diseases')}}:</strong> {{ chronic_diseases ||  $t('not_specified') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>



                <v-card outlined class="mb-4">

                    <v-card-text>
                        <div class="text-overline">
                            {{$t('patient_category')}}
                            <div>{{symptom_category}}</div>
                        </div>
                        <v-chip-group multiple>
                            <v-chip v-for="symptom in symptoms" :key="symptom.id">{{ symptom.name }}</v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>

                <v-card outlined class="mb-4" v-if="callHistory.length > 0">
                    <v-card-title class="headline">{{$t('call_history')}}</v-card-title>
                    <v-timeline align-top dense>
                        <v-timeline-item
                            v-for="(event, index) in callHistory"
                            :key="index"
                            :color="event.color"
                        >

                            <template v-slot:default>
                                <v-card>
                                    <v-card-title>{{ event.status }}</v-card-title>
                                    <v-card-text>{{ event.time }}</v-card-text>
                                </v-card>
                            </template>
                        </v-timeline-item>
                    </v-timeline>
                </v-card>

                <v-card outlined class="mb-4">
                    <v-card-title class="headline">{{$t('doctor_appointments')}}</v-card-title>
                    <v-card-text>
                        <p v-if="prescription">
                            {{ prescription }}
                        </p>
                        <p v-else>{{$t('there_are_no_appointments')}}.</p>

                        <v-chip-group multiple>
                            <v-chip v-for="medication in medications" :key="'medication-' + medication.id">{{ medication.name }} {{medication.dosage}}</v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>


                <v-card outlined class="mb-4" v-if="doctor.id">
                    <v-card-title class="headline">{{$t('information_about_doctor')}}</v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar size="64">
                                        <img v-if="doctor.photo" :src="doctor.photo" :alt="$t('doctor')">
                                        <v-icon v-else color="primary" size="30">mdi-account</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title><strong>{{ doctor.name }}</strong></v-list-item-title>
                                    <v-list-item-subtitle>{{$t('category')}}: {{ doctor.category }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title><strong>{{$t('specialty')}}:</strong> {{ doctor.specialty }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title><strong>{{$t('phone')}}:</strong> {{ formatPhoneNumber(doctor.phone) }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title><strong>{{$t('certificate_number')}}:</strong> {{ doctor.certificate }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title><strong>{{$t('clinic')}}:</strong> {{ doctor.clinic || $t('clinic_is_not_listed') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {mask} from 'vue-the-mask'
import {loadYmap, yandexMap} from "vue-yandex-maps"
import $ from "jquery";

export default {
    name: 'EmergencyCallShow',
    directives: {
        mask,
    },
    // components: {
    //     yandexMap
    // },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,

            phoneRaw: null,
            caller_phoneRaw: null,
            caller_name: null,
            iin: null,
            name: null,
            chronic_diseases: null,
            allergies: null,
            address: null,
            age: null,
            sex: null,
            sexItems: [],
            symptom_category: null,
            symptomCategoryItems: [],
            symptoms: [],
            symptomItems: [],
            city: null,
            cityItems: [],
            notes: null,
            medications: [],


            point_x: window.innerWidth / 2 - 24,
            point_y: window.innerHeight / 2 - 48,
            ymaps: null,
            coordinates: [],
            showMap: false,
            showAllMarkers: false,
            mapMarker: null,
            hintMapContent: null,
            marker: null,
            zoom: 18,
            markerIcon: {
                preset: 'islands#redDotIcon',
                balloonShadow: false,
                balloonPanelMaxMapArea: 0,
                hideIconOnBalloonOpen: false,
                useMapMarginInDragging: true,
                layout: 'default#image',
                imageHref: '../img/marker.png',
                imageSize: [32, 32],
                imageOffset: [-32, -32],
                //imageOffset: [0, 0],
                //content: '123 v12',
                contentOffset: [-130, 20],
                //contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
            },
            myMap: null,
            geocoderMetaDataCash:{
                point: [],
                street: null,
                house: null,
            },
            street: null,
            house: null,
            entrance: null,
            floor: null,
            apartment: null,
            block: null,
            housing: null,
            zip: null,
            prescription: null,

            callHistory: [],

            doctor: {
                id: null,
                name: null,
                phone: null,
                category: null,
                certificate: null,
                photo: null,
                clinic: null,
            },



        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        caller_phone: function () {
            return this.changePhone(this.caller_phoneRaw)
        },
        coords: {
            get: function () {
                return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [0, 0]
            },
            set: function (val) {
                this.coordinates = (val && val.length === 2) ? val : [0, 0]
            }
        },
        mapSettings() {
            if (this.language === 'en') {
                this.$yandex.lang = 'en_US'
            } else if (this.language === 'kk') {
                this.$yandex.lang = 'ru_RU'
            } else {
                this.$yandex.lang = 'ru_RU'
            }
            return this.$yandex
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getSexes()
        await this.getSymptomCategories()
        await this.getCities()
        await this.checkCreate()
        await this.ymapInit()
    },
    methods: {
        checkCreate() {
            this.heading = this.$t('emergency_call_creation')
            this.getEmergencyCall()
            this.heading = this.$route.params.status==='new' ? this.$t('menu_emergency_calls') :  this.$t('menu_emergency_call_'+this.$route.params.status)

        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getEmergencyCall() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/emergency_call/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.name = res.body.data.name
                    this.prescription = res.body.data.prescription.description
                    this.caller_name = res.body.data.caller_name
                    this.caller_phoneRaw = res.body.data.caller_phone
                    this.city = res.body.data.city.name
                    this.symptom_category = res.body.data.symptom_category.name
                    this.symptoms = res.body.data.symptoms
                    this.symptomItems = res.body.data.symptoms
                    this.notes = res.body.data.notes
                    this.iin = res.body.data.patient.iin
                    this.age = res.body.data.patient.age
                    this.sex = res.body.data.patient.sex.name
                    this.phoneRaw = res.body.data.patient.phone
                    this.zip = res.body.data.patient.zip
                    this.street = res.body.data.patient.street
                    this.house = res.body.data.patient.house
                    this.entrance = res.body.data.patient.entrance
                    this.floor = res.body.data.patient.floor
                    this.apartment = res.body.data.patient.apartment
                    this.block = res.body.data.patient.block
                    this.housing = res.body.data.patient.housing
                    this.name = res.body.data.patient.name
                    this.chronic_diseases = res.body.data.patient.chronic_diseases
                    this.allergies = res.body.data.patient.allergies
                    this.medications = res.body.data.medications
                    this.coordinates = [res.body.data.patient.point[1], res.body.data.patient.point[0]]
                    if(res.body.data && res.body.data.doctor && res.body.data.doctor.id)
                        this.doctor = {
                            id: res.body.data.doctor.id,
                            name: res.body.data.doctor.name,
                            phone: res.body.data.doctor.phone,
                            category: res.body.data.doctor.doctor_category.name,
                            specialty: res.body.data.doctor.specialty.name,
                            certificate: res.body.data.doctor.certificate,
                            photo: res.body.data.doctor.photo,
                            clinic: res.body.data.doctor.company.name,
                        };


                    if( res.body.data){

                        if( res.body.data.call_received_at){
                            this.callHistory.push({
                                time: res.body.data.call_received_at,
                                status: this.$t('time_of_call'),
                                color: "blue",
                            })
                        }
                        if( res.body.data.crew_departed_at){
                            this.callHistory.push({
                                time: res.body.data.crew_departed_at,
                                status: this.$t('call_is_accepted'),
                                color: "blue",
                            })
                        }
                        if( res.body.data.crew_arrived_at){
                            this.callHistory.push({
                                time: res.body.data.crew_arrived_at,
                                status: this.$t('arrival_time_at_place'),
                                color: "blue",
                            })
                        }
                        if( res.body.data.call_completed_at){
                            this.callHistory.push({
                                time: res.body.data.call_completed_at,
                                status: this.$t('end_time_of_call'),
                                color: "green",
                            })
                        }
                        if( res.body.data.call_canceled_at){
                            this.callHistory.push({
                                time: res.body.data.call_canceled_at,
                                status: this.$t('call_cancellation_time'),
                                color: "red",
                            })
                        }
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_emergency_call'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveEmergencyCall() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.caller_name) {
                formData.append('caller_name', this.caller_name)
            }
            if (this.caller_phoneRaw) {
                formData.append('caller_phone', this.caller_phoneRaw)
            }
            if (this.iin) {
                formData.append('iin', this.iin)
            }
            if (this.phoneRaw) {
                formData.append('phone', this.phoneRaw)
            }
            if (this.name) {
                formData.append('name', this.name)
            }
            if (this.sex) {
                if (this.sex.id) {
                    formData.append('sex_id', this.sex.id)
                } else {
                    formData.append('sex_id', this.sex)
                }
            }
            if (this.age) {
                formData.append('age', this.age)
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append('city_id', this.city.id)
                } else {
                    formData.append('city_id', this.city)
                }
            }
            if (this.street) {
                formData.append('street', this.street)
            }
            if (this.house) {
                formData.append('house', this.house)
            }
            if (this.entrance) {
                formData.append('entrance', this.entrance)
            }
            if (this.floor) {
                formData.append('floor', this.floor)
            }
            if (this.apartment) {
                formData.append('apartment', this.apartment)
            }
            if (this.block) {
                formData.append('block', this.block)
            }
            if (this.housing) {
                formData.append('housing', this.housing)
            }
            if (this.zip) {
                formData.append('zip', this.zip)
            }


            if (this.symptom_category) {
                if (this.symptom_category.id) {
                    formData.append('symptom_category_id', this.symptom_category.id)
                } else {
                    formData.append('symptom_category_id', this.symptom_category)
                }
            }

            if (this.symptoms) {
                formData.append('symptoms', this.symptoms)
            }

            if (this.chronic_diseases) {
                formData.append('chronic_diseases', this.chronic_diseases)
            }

            if (this.allergies) {
                formData.append('allergies', this.allergies)
            }

            if (this.notes) {
                formData.append('notes', this.notes)
            }

            if (this.coords && this.coords.length > 0) {
                for (let i in this.coords) {
                    formData.append(`point[${i}]`, this.coords[i])
                }
            }

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/emergency_call/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('emergency_call_has_been_updated'))
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('emergency_call_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/emergency_call', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('emergency_call_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                              this.$router.push({
                                name: 'emergency_call.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'emergency_call.status',
                                params: {
                                    status: this.$route.params.status
                                }})
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('emergency_call_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getSexes() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/sex`, {
                    params: params,
                })
                .then(res => {
                    this.sexItems = res.body.data;
                })
                .catch(err => {
                    this.sexItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                })
        },
        async getSymptomCategories() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/symptom_category`, {
                    params: params,
                })
                .then(res => {
                    this.symptomCategoryItems = res.body.data;
                })
                .catch(err => {
                    this.symptomCategoryItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_symptom_categories'))
                })
        },
        async getSymptoms() {
            let params = {};
            params.filter = 'all'
            params.symptom_category_id = this.symptom_category
            await this.$http
                .get(`admin/symptom`, {
                    params: params,
                })
                .then(res => {
                    this.symptomItems = res.body.data;
                })
                .catch(err => {
                    this.symptomItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_symptoms'))
                })
        },
        async getCities() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/city`, {
                    params: params,
                })
                .then(res => {
                    this.cityItems = res.body.data;
                })
                .catch(err => {
                    this.cityItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_cities'))
                })
        },
        async ymapInit() {
            var _this = this
            if (typeof (window.ymaps) !== "undefined") {
                if(window.ymaps){
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }

            }

            await loadYmap({...this.mapSettings, debug: false})
            if (window.ymaps) {
                window.ymaps.ready(function () {
                    _this.showMap = false
                })
            }
        },
        initHandler(e) {
            var _this = this;
            if (this.coords && (this.coords.length !== 2 || (this.coords.length === 2 && this.coords[0] === 0 && window.ymaps)) ) {

                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: false,
                    autoReverseGeocode: true,
                })
                location.then(
                    async function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                        e.setCenter(result.geoObjects.position, 18, {duration: 300});
                        e.container.getElement().style.backgroundColor = 'white';
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }

            _this.myMap = e;

            _this.myMap.geoObjects.options.set('draggable', true)
            _this.myMap.geoObjects.options.set('openBalloonOnClick', false);

            _this.myMap.container.enterFullscreen();
            let mySearchControl = new window.ymaps.control.SearchControl({
                options:
                    {
                        float: 'right',
                        floatIndex: 100,
                        zoom: 18,
                        noPlacemark: true,
                        noPopup: true,
                        fitMaxWidth: true,
                        noSuggestPanel: true,
                        position: {
                            top: 10,
                            right: 55
                        }
                    }
            });
            _this.myMap.controls.add(mySearchControl);
            // Нажатие кнопки найти в
            mySearchControl.events.add('submit', function () {
                let query = mySearchControl.getRequestString();
                window.ymaps.geocode(query, {
                    // Указываем, что ответ должен быть в формате JSON.
                    json: true,
                    // Устанавливаем лимит на кол-во записей в ответе.
                    results: 1
                }).then(function (result) {
                    let point = result.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
                    let coords = point.split(" ")
                    _this.myMap.setCenter([coords[1],coords[0]],18);
                }, this);
            }, this);


            let
                CustomControlClass = function (options) {
                    CustomControlClass.superclass.constructor.call(this, options);
                    this._$content = null;
                    this._geocoderDeferred = null;
                };
            // И наследуем его от collection.Item.
            new window.ymaps.util.augment(CustomControlClass, window.ymaps.collection.Item, {
                onAddToMap: function (map) {
                    CustomControlClass.superclass.onAddToMap.call(this, map);
                    this._lastCenter = null;
                    this.getParent().getChildElement(this).then(this._onGetChildElement, this);
                },

                onRemoveFromMap: function (oldMap) {
                    this._lastCenter = null;
                    if (this._$content) {
                        this._$content.remove();
                        this._mapEventGroup.removeAll();
                    }
                    CustomControlClass.superclass.onRemoveFromMap.call(this, oldMap);
                },

                _onGetChildElement: function (parentDomContainer) {
                    // Создаем HTML-элемент с текстом.
                    this._$content =    $('<div class="customControl" style="font-weight: bold;font-size: 1.1em;position: fixed;position: fixed;text-align: center !important;width:100% ">Укажите адрес доставки</div>').appendTo(parentDomContainer);
                    this._mapEventGroup = this.getMap().events.group();
                    // Запрашиваем данные после изменения положения карты.
                    this._mapEventGroup.add('boundschange', this._createRequest, this);
                    // Сразу же запрашиваем название места.
                    this._createRequest();
                },

                _createRequest: function() {
                    var lastCenter = this._lastCenter = this.getMap().getCenter().join(',');
                    _this.geocoderMetaDataCash.point = this._lastCenter
                    // Запрашиваем информацию о месте по координатам центра карты.
                    window.ymaps.geocode(this._lastCenter, {
                        // Указываем, что ответ должен быть в формате JSON.
                        json: true,
                        // Устанавливаем лимит на кол-во записей в ответе.
                        results: 1
                    }).then(function (result) {
                        // Будем обрабатывать только ответ от последнего запроса.
                        if (lastCenter === this._lastCenter) {
                            this._onServerResponse(result);
                        }
                    }, this);
                },

                _onServerResponse: function (result) {
                    // Данные от сервера были получены и теперь их необходимо отобразить.
                    // Описание ответа в формате JSON.
                    var members = result.GeoObjectCollection.featureMember,
                        geoObjectData = (members && members.length) ? members[0].GeoObject : null;
                    if (geoObjectData) {
                        //Добавляем текст на карте сверху
                        //console.log(geoObjectData.metaDataProperty.GeocoderMetaData.text);
                        this._$content.text(geoObjectData.metaDataProperty.GeocoderMetaData.text);
                        //Доавляем данные в переменные
                        let GeocoderMetaData = geoObjectData.metaDataProperty.GeocoderMetaData
                        if (GeocoderMetaData.Address.Components) {
                            let Components = GeocoderMetaData.Address.Components
                            let street_full = []
                            let district = Components.filter(item => item.kind === 'district')
                            if (district.length > 0) {
                                street_full.push(district[0].name)
                            }
                            let street = Components.filter(item => item.kind === 'street')
                            if (street.length > 0) {
                                street_full.push(street[0].name)
                            }
                            let house = Components.filter(item => item.kind === 'house')
                            if (house.length > 0) {
                                _this.geocoderMetaDataCash.house = house[0].name
                            }
                            _this.geocoderMetaDataCash.street = street_full.join(', ')
                        }

                    }
                }
            });

            var customControl = new CustomControlClass();
            _this.myMap.controls.add(customControl, {
                float: 'none',
                position: {
                    top: 50
                }
            });


            // Создаем курсор по центру
            let ButtonLayoutCursor = window.ymaps.templateLayoutFactory.createClass([
                    '<div class="my-button">',
                    '<img class="my-button__img" src="{{ data.image }}" />',
                    '</div>'
                ].join('')),

                buttonCursor = new window.ymaps.control.Button({
                    data: {
                        image: '../../img/marker.png',
                    },
                    options: {
                        layout: ButtonLayoutCursor,
                        maxWidth: [170, 190, 220]
                    }
                });

            _this.myMap.controls.add(buttonCursor, {

                float: 'none',
                position: {
                    top: this.point_y,
                    left: this.point_x
                }
            });


            //--- создание кнопка закрыть вверху слево
            let ButtonLayoutClose = window.ymaps.templateLayoutFactory.createClass([
                    '<div style="background-color:#ffdb4d;padding:4px 5px 3px 5px;" title="{{ data.title }}" class="my-button small">',
                    '<img  class="my-button__img__close" src="{{ data.image }}" alt="X">',
                    '</div>'
                ].join('')),

                buttonClose = new window.ymaps.control.Button({
                    data: {
                        image: '../../img/close.svg',
                        title: "Закрыть"
                    },
                    options: {
                        layout: ButtonLayoutClose,
                        size: 'small',
                    }
                });

            _this.myMap.controls.add(buttonClose, {
                float: 'none',
                position: {
                    top: 10,
                    right: 20

                }
            });

            buttonClose.events.add('click', function () {
                _this.showMapClose()

            });



            // ----------- Создание кнопки Готово снизу  -----------------------
            let ButtonLayout = window.ymaps.templateLayoutFactory.createClass([
                    '<div title="{{ data.title }}" class="my-button" style="position: fixed;width: 100%;text-align: center;">',
                    '<button class="my-button__text" style="background-color: #E44812;font-size: 1.2em;padding:0.7em 1em;border: solid 1px #E0E4E6;font-weight: bold;color:#fff;">{{ data.content }}</button>',
                    '</div>'
                ].join('')),

                buttonOK = new window.ymaps.control.Button({
                    data: {
                        content: this.$t('done'),
                        title: this.$t('done')
                    },
                    options: {
                        layout: ButtonLayout,

                    }
                });

            _this.myMap.controls.add(buttonOK, {
                float: 'none',
                position: {
                    bottom: 100
                }
            });

            buttonOK.events.add('click', function (e) {
                _this.MapButtonOK()
            });





        },
        openMap(){
            this.showMap = true;
        },
        onBoundsChange(e) {
            var _this = this
            this.coords = e.get('newCenter')
            var myGeocoder = window.ymaps.geocode(this.coords)
            myGeocoder.then(
                function (res) {
                    let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                    if (GeocoderMetaData.Address.Components) {
                        let Components = GeocoderMetaData.Address.Components
                        let street_full = []
                        let district = Components.filter(item => item.kind === 'district')
                        if (district.length > 0) {
                            street_full.push(district[0].name)
                        }
                        let street = Components.filter(item => item.kind === 'street')
                        if (street.length > 0) {
                            street_full.push(street[0].name)
                        }
                        let house = Components.filter(item => item.kind === 'house')
                        if (house.length > 0) {
                            _this.house = house[0].name
                        }
                        _this.street = street_full.join(', ')
                    }
                },
                function (err) {
                    console.log('Error Load GeocoderMetaData')
                }
            )
        },
        showMapClose(){
            this.myMap.destroy();
            this.myMap = null;
            this.showMap = false;
        },
        MapButtonOK(){
            this.coords = this.geocoderMetaDataCash.point.split(',');
            this.street = this.geocoderMetaDataCash.street;
            this.house = this.geocoderMetaDataCash.house;
            this.myMap.destroy();
            this.myMap = null;
            this.showMap = false;
        },
        setCoordinate(){
            let city = this.cityItems.find(item => item.id === this.city);
            this.coordinates = city.point;
            this.markerIcon.contentOffset = city.point;
        }
    }
}
</script>
